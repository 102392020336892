// Copyright 1999-2024. WebPros International GmbH. All rights reserved.

import * as React from 'react';
import {
    Grid,
    GridCol,
    Translate,
} from '@plesk/ui-library';
import { OsImageCard } from 'common/components/serverType/OsImageCard';
import { PageSubHeader } from 'client/common/components';
import InfiniteScroll from 'common/components/InfinityScroll/InfinityScroll';
import { RootState } from 'client/core/store';
import {
    bindActionCreators,
    Dispatch,
} from 'redux';
import { connect } from 'react-redux';
import { loadOsImagesOnScroll } from 'common/modules/osImage/actions';
import {
    Tab,
    TabList,
    TabPanel,
    Tabs,
} from 'react-tabs';
import { ApplicationCard } from 'common/components/serverType/ApplicationCard';
import { PROJECT_SERVER_PAGE } from 'client/project/constants/tests';
import { JSONSchema7 } from 'json-schema';
import { WrappedApplicationForm } from 'common/components/serverType/Styles';
import JSONSchemaForm,
{
    ISubmitEvent,
    IJSONSchemaForm,
} from 'common/components/JSONSchemaForm/JSONSchemaForm';
import { IPaginateApiResponse } from 'common/api/resources/Response';
import { IApplicationResponse } from 'common/api/resources/Application';
import { IOsImageResponse } from 'common/api/resources/OsImage';
import validator from '@rjsf/validator-ajv8';

interface IServerTypeTabsProps {
    selectedOsImageVersionId: number;
    selectedApplicationId: number;
    onVersionSelected: (id: number) => void;
    onApplicationSelected: (id: number) => void;
    onChange: (e: ISubmitEvent) => void;
    loadOnScroll: () => void;
    onError: () => void;
    applications: IPaginateApiResponse<IApplicationResponse[]>;
    osImages: IPaginateApiResponse<IOsImageResponse[]>;
    formData?: Record<string, unknown>;
    formRef?: React.RefObject<IJSONSchemaForm>;
    hideApplications?: boolean;
}

export type ServerTypeTabsProps =
    IServerTypeTabsProps &
    ReturnType<typeof mapStateToProps> &
    ReturnType<typeof mapDispatchToProps>;

export const ServerTypeTabs: React.FC<ServerTypeTabsProps> = ({
    applications,
    osImages,
    selectedOsImageVersionId,
    selectedApplicationId,
    onVersionSelected,
    onApplicationSelected,
    onChange,
    loadOnScroll,
    onError,
    formData,
    formRef,
    hideApplications,
}) => {
    const renderApplicationCustomForm = () => {
        const selectedApplication = applications.data.find(item => item.id === selectedApplicationId);
        try {
            return selectedApplication && selectedApplication.json_schema && (
                <WrappedApplicationForm>
                    <JSONSchemaForm
                        key={selectedApplicationId.toString()}
                        onChange={onChange}
                        liveValidate={false}
                        showErrorList={false}
                        onError={onError}
                        formData={formData}
                        schema={JSON.parse(selectedApplication.json_schema) as JSONSchema7}
                        children={true}
                        ref={formRef}
                        validator={validator}
                    />
                </WrappedApplicationForm>
            );
        } catch (e) {
            // just return null on empty or invalid schema
            return null;
        }
    };
    const isApplicationShown = applications.data.length > 0 && !hideApplications;

    return React.useMemo(() => (
        <>
            {(osImages.data.length > 0 || isApplicationShown) && <>
                <PageSubHeader title="servers.create.serverType.sectionTitle" />
                <Tabs>
                    <TabList style={{
                        width: (osImages.data.length > 0 && isApplicationShown) ? '245px' : '0px',
                    }}>
                        {osImages.data.length > 0 && (
                            <Tab>
                                <Translate content="servers.create.serverType.tabs.operatingSystems" />
                            </Tab>
                        )}
                        {isApplicationShown && (
                            <Tab data-cy={PROJECT_SERVER_PAGE.APPLICATION_TAB}>
                                <Translate content="servers.create.serverType.tabs.applications" />
                            </Tab>
                        )}
                    </TabList>
                    {osImages.data.length > 0 && <TabPanel>
                        <InfiniteScroll
                            loadMore={loadOnScroll}
                            hasMore={!!osImages.links.next}
                        >
                            <Grid style={{ marginBottom: '-24px' }} minColumnWidth={210} gap="lg">
                                {osImages.data.map((osImage, key) => (
                                    <GridCol key={key}>
                                        <OsImageCard
                                            onVersionSelected={onVersionSelected}
                                            selectedId={selectedOsImageVersionId}
                                            osImage={osImage}
                                        />
                                    </GridCol>
                                ))}
                            </Grid>
                        </InfiniteScroll>
                    </TabPanel>}
                    {isApplicationShown && <TabPanel>
                        <Grid minColumnWidth={190} gap="lg">
                            {applications.data.map((application, key) => (
                                <GridCol key={key}>
                                    <ApplicationCard
                                        onApplicationSelected={onApplicationSelected}
                                        isSelected={application.id === selectedApplicationId}
                                        application={application}
                                    />
                                </GridCol>
                            ))}
                        </Grid>
                        <Grid style={{ marginBottom: '-24px' }} minColumnWidth={380} gap="lg">
                            <GridCol>
                                {renderApplicationCustomForm()}
                            </GridCol>
                        </Grid>
                    </TabPanel>}
                </Tabs>
            </>}
        </>
        // eslint-disable-next-line react-hooks/exhaustive-deps
    ), [
        selectedOsImageVersionId,
        selectedApplicationId,
        osImages,
        applications,
    ]);
};

const mapStateToProps = (state: RootState) => ({
    osImages: state.osImage.list,
    applications: state.application.list,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    loadOnScroll: bindActionCreators(loadOsImagesOnScroll, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(ServerTypeTabs);
