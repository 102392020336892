// Copyright 1999-2024. WebPros International GmbH. All rights reserved.

import { IComputeResourceResponse } from 'common/api/resources/ComputeResource';
import {
    IPaginated,
    IRequest,
} from 'common/api/resources/Request/request';
import { IVmResponse } from 'common/api/resources/ComputeResourceVm';

export enum TASK_STATUS {
    NOT_STARTED = 'not_started',
    PENDING = 'pending',
    STATUS_WAITING_FOR_PREVIOUS_TASKS = 'waiting_for_previous_tasks',
    QUEUED = 'queued',
    RUNNING = 'running',
    CANCELED = 'canceled',
    DONE = 'done',
    DONE_WITH_ERRORS = 'done_with_errors',
    FAILED = 'failed'
}

// actions should be synced with backend/api/v1/Task/Models/Task.php
export enum TASK_ACTION {
    SERVER_CREATE = 'vm-create',
    SERVER_REINSTALL = 'vm-reinstall',
    SERVER_DELETE = 'vm-delete',
    SERVER_UPDATE = 'vm-update',
    SERVER_PASSWORD_CHANGE = 'vm-password-change',
    SERVER_START = 'vm-start',
    SERVER_STOP = 'vm-stop',
    SERVER_RESTART = 'vm-restart',
    SERVER_SUSPEND = 'vm-suspend',
    SERVER_RESUME = 'vm-resume',
    SERVER_RESIZE = 'vm-resize',
    SERVER_UPDATE_LIMITS_ON_RESIZE = 'vm-update-limits-on-resize',
    SERVER_CHANGE_PLAN = 'vm-change-plan',
    SERVERS_MIGRATE = 'vms-migrate',
    SERVER_MIGRATE = 'vm-migrate',
    SERVER_UPDATE_NETWORK_ADD_IPS = 'vm-update-network-add-ips',
    SERVER_UPDATE_NETWORK_DELETE_IPS = 'vm-update-network-delete-ips',
    SERVER_UPDATE_NETWORK_CHANGE_PRIMARY_IP = 'vm-update-network-change-primary-ip',
    SERVER_UPDATE_LIMITS_ON_RESET_USAGE = 'vm-update-limits-on-reset-usage',
    SERVERS_UPDATE_LIMITS = 'vms-update-limits',
    DNS_RECORDS_REGISTER = 'dns-record-register',
    DNS_RECORDS_UNREGISTER = 'dns-records-unregister',
    DNS_RECORDS_UPDATE = 'dns-record-update',
    REVERSE_DNS_RECORDS_REGISTER = 'reverse-dns-record-register',
    REVERSE_DNS_RECORDS_UNREGISTER = 'reverse-dns-record-unregister',
    SNAPSHOT_CREATE = 'snapshot-create',
    SNAPSHOT_DELETE = 'snapshot-delete',
    SNAPSHOT_REVERT = 'snapshot-revert',
    PREPARE_INSTALLER_FOR_UPDATE = 'prepare installer for version update',
    RUN_VERSION_UPDATE = 'run version update',
    BACKUP_CREATE = 'backup-create',
    BACKUP_RESTORE = 'backup-restore',
    BACKUP_DELETE = 'backup-delete',
    BACKUP_ROTATE = 'backup-rotate',
    BACKUP_PURGE_COMPUTE_RESOURCE_VM = 'backup-purge-compute-resource-vm',
    MANAGEMENT_NODE_BACKUP_CREATE = 'management-node-backup-create',
    MANAGEMENT_NODE_BACKUP_RESTORE = 'management-node-backup-restore',
    MANAGEMENT_NODE_BACKUP_DELETE = 'management-node-backup-delete',
    MANAGEMENT_NODE_BACKUP_ROTATE = 'management-node-backup-rotate',
    MANAGEMENT_NODE_BACKUP_RECOVER = 'management-node-backup-recover',
    CONFIGURE_NETWORK = 'configure network',
    UPDATE_NETWORK_RULES = 'update network rules',
    UPGRADE_COMPUTE_RESOURCE = 'upgrade compute resource',
    CLEAR_IMAGE_CACHE = 'clear image cache',
    CHANGE_HOSTNAME = 'change hostname',
    PREPARE_CLUSTER_IMPORT = 'prepare cluster import',
    REFRESH_CLUSTER_IMPORT = 'refresh cluster import',
    CLUSTER_IMPORT = 'cluster import',
}

export const TASK_STATUS_TRANSLATION = {
    [TASK_ACTION.SERVER_CREATE]: {
        [TASK_STATUS.FAILED]: 'toasts.task.serverCreateFailed',
        [TASK_STATUS.DONE]: 'toasts.task.serverCreateDone',
    },
    [TASK_ACTION.SERVER_REINSTALL]: {
        [TASK_STATUS.FAILED]: 'toasts.task.serverReinstallFailed',
        [TASK_STATUS.DONE]: 'toasts.task.serverReinstallDone',
    },
    [TASK_ACTION.SERVER_DELETE]: {
        [TASK_STATUS.FAILED]: 'toasts.task.serverDeleteFailed',
        [TASK_STATUS.DONE]: 'toasts.task.serverDeleteDone',
    },
    [TASK_ACTION.SERVER_UPDATE]: {
        [TASK_STATUS.FAILED]: 'toasts.task.serverUpdateFailed',
        [TASK_STATUS.DONE]: 'toasts.task.serverUpdateDone',
    },
    [TASK_ACTION.SERVER_PASSWORD_CHANGE]: {
        [TASK_STATUS.FAILED]: 'toasts.task.serverPasswordChangeFailed',
        [TASK_STATUS.DONE]: 'toasts.task.serverPasswordChangeDone',
    },
    [TASK_ACTION.SERVER_START]: {
        [TASK_STATUS.FAILED]: 'toasts.task.serverStartFailed',
        [TASK_STATUS.DONE]: 'toasts.task.serverStartDone',
    },
    [TASK_ACTION.SERVER_STOP]: {
        [TASK_STATUS.FAILED]: 'toasts.task.serverStopFailed',
        [TASK_STATUS.DONE]: 'toasts.task.serverStopDone',
    },
    [TASK_ACTION.SERVER_RESTART]: {
        [TASK_STATUS.FAILED]: 'toasts.task.serverRestartFailed',
        [TASK_STATUS.DONE]: 'toasts.task.serverRestartDone',
    },
    [TASK_ACTION.SERVER_SUSPEND]: {
        [TASK_STATUS.FAILED]: 'toasts.task.serverSuspendFailed',
        [TASK_STATUS.DONE]: 'toasts.task.serverSuspendDone',
    },
    [TASK_ACTION.SERVER_RESUME]: {
        [TASK_STATUS.FAILED]: 'toasts.task.serverResumeFailed',
        [TASK_STATUS.DONE]: 'toasts.task.serverResumeDone',
    },
    [TASK_ACTION.SERVER_RESIZE]: {
        [TASK_STATUS.FAILED]: 'toasts.task.serverResizeFailed',
        [TASK_STATUS.DONE]: 'toasts.task.serverResizeDone',
    },
    [TASK_ACTION.SERVER_UPDATE_LIMITS_ON_RESIZE]: {
        [TASK_STATUS.FAILED]: 'toasts.task.serverResizeFailed',
        [TASK_STATUS.DONE]: 'toasts.task.serverResizeDone',
    },
    [TASK_ACTION.SERVER_CHANGE_PLAN]: {
        [TASK_STATUS.FAILED]: 'toasts.task.serverResizeFailed',
        [TASK_STATUS.DONE]: 'toasts.task.serverResizeDone',
    },
    [TASK_ACTION.SERVERS_MIGRATE]: {
        [TASK_STATUS.FAILED]: 'toasts.task.serversMigrateFailed',
        [TASK_STATUS.DONE]: 'toasts.task.serversMigrateDone',
    },
    [TASK_ACTION.SERVER_MIGRATE]: {
        [TASK_STATUS.FAILED]: 'toasts.task.serverMigrateFailed',
        [TASK_STATUS.DONE]: 'toasts.task.serverMigrateDone',
    },
    [TASK_ACTION.SERVER_UPDATE_NETWORK_ADD_IPS]: {
        [TASK_STATUS.FAILED]: 'toasts.task.serverUpdateNetworkAddIpsFailed',
        [TASK_STATUS.DONE]: 'toasts.task.serverUpdateNetworkAddIpsDone',
    },
    [TASK_ACTION.SERVER_UPDATE_NETWORK_DELETE_IPS]: {
        [TASK_STATUS.FAILED]: 'toasts.task.serverUpdateNetworkDeleteIpsFailed',
        [TASK_STATUS.DONE]: 'toasts.task.serverUpdateNetworkDeleteIpsDone',
    },
    [TASK_ACTION.SERVER_UPDATE_NETWORK_CHANGE_PRIMARY_IP]: {
        [TASK_STATUS.FAILED]: 'toasts.task.serverUpdateNetworkChangePrimaryIpFailed',
        [TASK_STATUS.DONE]: 'toasts.task.serverUpdateNetworkChangePrimaryIpDone',
    },
    [TASK_ACTION.SERVER_UPDATE_LIMITS_ON_RESET_USAGE]: {
        [TASK_STATUS.FAILED]: 'toasts.task.serverUpdateLimitsOnResetUsageFailed',
        [TASK_STATUS.DONE]: 'toasts.task.serverUpdateLimitsOnResetUsageDone',
    },
    [TASK_ACTION.SERVERS_UPDATE_LIMITS]: {
        [TASK_STATUS.FAILED]: 'toasts.task.updateLimitsFailed',
        [TASK_STATUS.DONE]: 'toasts.task.updateLimitsDone',
    },
    [TASK_ACTION.DNS_RECORDS_REGISTER]: {
        [TASK_STATUS.FAILED]: 'toasts.task.dnsRecordsRegisterFailed',
        [TASK_STATUS.DONE]: 'toasts.task.dnsRecordsRegisterDone',
    },
    [TASK_ACTION.DNS_RECORDS_UNREGISTER]: {
        [TASK_STATUS.FAILED]: 'toasts.task.dnsRecordsUnregisterFailed',
        [TASK_STATUS.DONE]: 'toasts.task.dnsRecordsUnregisterDone',
    },
    [TASK_ACTION.DNS_RECORDS_UPDATE]: {
        [TASK_STATUS.FAILED]: 'toasts.task.dnsRecordsUpdateFailed',
        [TASK_STATUS.DONE]: 'toasts.task.dnsRecordsUpdateDone',
    },
    [TASK_ACTION.REVERSE_DNS_RECORDS_REGISTER]: {
        [TASK_STATUS.FAILED]: 'toasts.task.reverseDnsRecordsRegisterFailed',
        [TASK_STATUS.DONE]: 'toasts.task.reverseDnsRecordsRegisterDone',
    },
    [TASK_ACTION.REVERSE_DNS_RECORDS_UNREGISTER]: {
        [TASK_STATUS.FAILED]: 'toasts.task.reverseDnsRecordsUnregisterFailed',
        [TASK_STATUS.DONE]: 'toasts.task.reverseDnsRecordsUnregisterDone',
    },
    [TASK_ACTION.SNAPSHOT_CREATE]: {
        [TASK_STATUS.FAILED]: 'toasts.task.snapshotCreateFailed',
        [TASK_STATUS.DONE]: 'toasts.task.snapshotCreateDone',
    },
    [TASK_ACTION.SNAPSHOT_DELETE]: {
        [TASK_STATUS.FAILED]: 'toasts.task.snapshotDeleteFailed',
        [TASK_STATUS.DONE]: 'toasts.task.snapshotDeleteDone',
    },
    [TASK_ACTION.SNAPSHOT_REVERT]: {
        [TASK_STATUS.FAILED]: 'toasts.task.snapshotRevertFailed',
        [TASK_STATUS.DONE]: 'toasts.task.snapshotRevertDone',
    },
    [TASK_ACTION.PREPARE_INSTALLER_FOR_UPDATE]: {
        [TASK_STATUS.FAILED]: 'toasts.task.prepareInstallerForUpdateFailed',
        [TASK_STATUS.DONE]: 'toasts.task.prepareInstallerForUpdateDone',
    },
    [TASK_ACTION.RUN_VERSION_UPDATE]: {
        [TASK_STATUS.FAILED]: 'toasts.task.runVersionUpdateFailed',
        [TASK_STATUS.DONE]: 'toasts.task.runVersionUpdateDone',
    },
    [TASK_ACTION.BACKUP_CREATE]: {
        [TASK_STATUS.FAILED]: 'toasts.task.backupCreateFailed',
        [TASK_STATUS.DONE]: 'toasts.task.backupCreateDone',
    },
    [TASK_ACTION.BACKUP_RESTORE]: {
        [TASK_STATUS.FAILED]: 'toasts.task.backupRestoreFailed',
        [TASK_STATUS.DONE]: 'toasts.task.backupRestoreDone',
    },
    [TASK_ACTION.BACKUP_DELETE]: {
        [TASK_STATUS.FAILED]: 'toasts.task.backupDeleteFailed',
        [TASK_STATUS.DONE]: 'toasts.task.backupDeleteDone',
    },
    [TASK_ACTION.BACKUP_ROTATE]: {
        [TASK_STATUS.FAILED]: 'toasts.task.backupRotateFailed',
        [TASK_STATUS.DONE]: 'toasts.task.backupRotateDone',
    },
    [TASK_ACTION.BACKUP_PURGE_COMPUTE_RESOURCE_VM]: {
        [TASK_STATUS.FAILED]: 'toasts.task.backupPurgeComputeResourceVmFailed',
        [TASK_STATUS.DONE]: 'toasts.task.backupPurgeComputeResourceVmDone',
    },
    [TASK_ACTION.MANAGEMENT_NODE_BACKUP_CREATE]: {
        [TASK_STATUS.FAILED]: 'toasts.task.managementNodeBackupCreateFailed',
        [TASK_STATUS.DONE]: 'toasts.task.managementNodeBackupCreateDone',
    },
    [TASK_ACTION.MANAGEMENT_NODE_BACKUP_RESTORE]: {
        [TASK_STATUS.FAILED]: 'toasts.task.managementNodeBackupRestoreFailed',
        [TASK_STATUS.DONE]: 'toasts.task.managementNodeBackupRestoreDone',
    },
    [TASK_ACTION.MANAGEMENT_NODE_BACKUP_DELETE]: {
        [TASK_STATUS.FAILED]: 'toasts.task.managementNodeBackupDeleteFailed',
        [TASK_STATUS.DONE]: 'toasts.task.managementNodeBackupDeleteDone',
    },
    [TASK_ACTION.MANAGEMENT_NODE_BACKUP_ROTATE]: {
        [TASK_STATUS.FAILED]: 'toasts.task.managementNodeBackupRotateFailed',
        [TASK_STATUS.DONE]: 'toasts.task.managementNodeBackupRotateDone',
    },
    [TASK_ACTION.MANAGEMENT_NODE_BACKUP_RECOVER]: {
        [TASK_STATUS.FAILED]: 'toasts.task.managementNodeBackupRecoverFailed',
        [TASK_STATUS.DONE]: 'toasts.task.managementNodeBackupRecoverDone',
    },
    [TASK_ACTION.CONFIGURE_NETWORK]: {
        [TASK_STATUS.FAILED]: 'toasts.task.configureNetworkFailed',
        [TASK_STATUS.DONE]: 'toasts.task.configureNetworkDone',
    },
    [TASK_ACTION.UPDATE_NETWORK_RULES]: {
        [TASK_STATUS.FAILED]: 'toasts.task.updateNetworkRulesFailed',
        [TASK_STATUS.DONE]: 'toasts.task.updateNetworkRulesDone',
    },
    [TASK_ACTION.UPGRADE_COMPUTE_RESOURCE]: {
        [TASK_STATUS.FAILED]: 'toasts.task.upgradeComputeResourceFailed',
        [TASK_STATUS.DONE]: 'toasts.task.upgradeComputeResourceDone',
    },
    [TASK_ACTION.CLEAR_IMAGE_CACHE]: {
        [TASK_STATUS.FAILED]: 'toasts.task.clearImageCacheFailed',
        [TASK_STATUS.DONE]: 'toasts.task.clearImageCacheDone',
    },
    [TASK_ACTION.CHANGE_HOSTNAME]: {
        [TASK_STATUS.FAILED]: 'toasts.task.changeHostnameFailed',
        [TASK_STATUS.DONE]: 'toasts.task.changeHostnameDone',
    },
    [TASK_ACTION.PREPARE_CLUSTER_IMPORT]: {
        [TASK_STATUS.FAILED]: 'toasts.task.prepareClusterImportFailed',
        [TASK_STATUS.DONE]: 'toasts.task.prepareClusterImportDone',
    },
    [TASK_ACTION.REFRESH_CLUSTER_IMPORT]: {
        [TASK_STATUS.FAILED]: 'toasts.task.refreshClusterImportFailed',
        [TASK_STATUS.DONE]: 'toasts.task.refreshClusterImportDone',
    },
    [TASK_ACTION.CLUSTER_IMPORT]: {
        [TASK_STATUS.FAILED]: 'toasts.task.clusterImportFailed',
        [TASK_STATUS.DONE]: 'toasts.task.clusterImportDone',
    },
};

export interface ITaskResponse {
    id: number;
    compute_resource: IComputeResourceResponse | null;
    user_email: string | null;
    compute_resource_vm_id: number | null;
    compute_resource_vm_uuid: string | null;
    compute_resource_vm: IVmResponse | null;
    action: string;
    status: string;
    output: string;
    duration: number;
    progress: number;
    started_at: string;
    finished_at: string | null;
    is_cancelable: boolean;
    is_retryable: boolean;
    backup_id: number | null;
    management_node_backup_id: number | null;
}

export interface IUpdateTaskEvent {
    id: number;
    status: string;
    is_cancelable: boolean;
    is_retryable: boolean;
    progress: number;
    duration: number;
    started_at: string;
    finished_at: string;
    compute_resource_vm_id: number | null;
    backup_id: number | null;
    management_node_backup_id: number | null;
    output: string;
}

export interface IUpdateUserTaskEvent {
    id: number;
    action: string;
    status: TASK_STATUS;
    user_id: number;
}

export interface IUpdateTask {
    task: IUpdateTaskEvent;
}

export interface IUpdateUserTask {
    task: IUpdateUserTaskEvent;
}

export interface ITaskListFilters {
    action?: string;
    status?: string;
    compute_resource_id?: number;
    compute_resource_vm_id?: number;
    version?: string;
    limit_to?: number;
}

export interface ITaskListRequest extends IRequest<ITaskListFilters>, IPaginated {}

export const isFinished = (task: ITaskResponse): boolean =>
    [TASK_STATUS.DONE, TASK_STATUS.DONE_WITH_ERRORS, TASK_STATUS.FAILED].includes(task.status as TASK_STATUS);
