// Copyright 1999-2024. WebPros International GmbH. All rights reserved.

import * as React from 'react';
import {
    Icon,
    Translate,
} from '@plesk/ui-library';
import {
    CutTitle,
    Tooltip,
} from 'common/components';
import {
    ICONS,
    INTENT_TYPE,
} from 'common/constants';
import {
    CardItem,
    ContentName,
    DescriptionText,
    ItemContent,
    LogoContainer,
    ItemParams,
} from 'common/modules/computeResourceVm/components/Styles';
import { IOfferResponse } from 'common/api/resources/Offer';
import { IProjectTokenPricingResponse } from 'common/api/resources/Project';
import { IPlanResponse } from 'common/api/resources/Plan';
import { OfferPrice } from 'common/modules/computeResourceVm/components/OfferPrice';
import { useDisabledEntitiesManager } from 'common/helpers/ManagerOfDisabledEntities';

export type IPrimaryDiskCardProps = {
    plan?: IPlanResponse;
    offer: IOfferResponse;
    tokenPricing?: IProjectTokenPricingResponse;
    icon: ICONS;
    isSelected: boolean;
    onItemClicked?: () => void;
    onRemoveClicked?: () => void;
} & React.HTMLProps<HTMLDivElement>;

export const PrimaryDiskCard: React.FC<IPrimaryDiskCardProps> = ({
    plan,
    tokenPricing,
    offer,
    icon,
    isSelected,
    onItemClicked,
}) => {

    const manager = useDisabledEntitiesManager();

    const offerStatus = React.useMemo(
        () => manager.getOfferStatus(offer),
        [manager, offer]
    );

    const tooltips = offerStatus?.isDisabled
        ? offerStatus
            .conflictMessages
            .map((conflictMessage, ind) => (
                <div key={ind}>
                    <Translate content={conflictMessage.messageKey} params={conflictMessage.params}/>
                </div>
            ))
        : null;

    const isDisabled = !!offerStatus?.isDisabled ?? false;

    const renderIcon = () => {
        if (isSelected) {
            return <Icon name={icon} />;
        }

        return (
            <Icon
                intent={INTENT_TYPE.INACTIVE}
                name={icon}
            />
        );
    };

    const handleClick = () => {
        if (!isDisabled && onItemClicked) {
            onItemClicked();
        }
    };

    return (
        <Tooltip shown={isDisabled} title={tooltips}>
            <CardItem isSelected={isSelected} onClick={handleClick} isDisabled={isDisabled}>
                <div>
                    <LogoContainer>
                        {renderIcon()}
                    </LogoContainer>
                </div>
                <ItemContent>
                    <ContentName>
                        <CutTitle title={offer.name} withTooltip={!isDisabled} />
                    </ContentName>
                    {offer.description && (
                        <DescriptionText>
                            <CutTitle title={offer.description} withTooltip={!isDisabled} />
                        </DescriptionText>
                    )}
                    {tokenPricing && (
                        <ItemParams>
                            <OfferPrice
                                plan={plan}
                                tokenPricing={tokenPricing}
                                offer={offer}
                                sizeGib={plan?.params.disk}
                            />
                        </ItemParams>
                    )}
                </ItemContent>
            </CardItem>
        </Tooltip>
    );
};
