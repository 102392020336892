// Copyright 1999-2024. WebPros International GmbH. All rights reserved.

import * as React from 'react';
import { connect } from 'react-redux';
import { PageSubHeader } from 'client/common/components';
import {
    Action,
    Button,
    copyToClipboard,
    Form,
    FormField,
    Grid,
    GridCol,
    Icon,
    Tooltip,
    Translate,
    useTranslate,
} from '@plesk/ui-library';
import LocationList from 'common/components/location/LocationList';
import PlanList from 'common/components/plan/PlanList';
import ServerTypeTabs from 'common/components/serverType/ServerTypeTabs';
import { RootState } from 'client/core/store';
import * as toasterActions from 'common/modules/app/toaster/actions';
import * as projectServerActions from 'common/modules/computeResourceVm/actions';
import {
    bindActionCreators,
    Dispatch,
} from 'redux';
import { Loader } from 'common/components';
import { LOADING_FLAGS } from 'common/modules/app/loadingFlags/constants';
import { RouteComponentProps } from 'react-router';
import { IProjectCreateVmRequestWithBackup } from 'common/api/resources/Project';
import { HTTP_CODES } from 'common/api/constants';
import ServerSSHKeys from 'common/components/ServerSSHKeys/ServerSSHKeys';
import {
    generateName,
    generatePassword,
} from 'common/helpers/vm';
import {
    Container,
    ErrorMessage,
    Footer,
    GenerateNameButton,
    ServerName,
    ServerNamesContainer,
    ServersContainer,
    SubmitButton,
} from 'client/project/containers/projectServer/ProjectServerCreate/Styles';
import { WithErrorPages } from 'common/components/WithPageNotFound/WithErrorPages';
import { isFeatureEnabled } from 'common/selectors';
import { FEATURE } from 'common/api/resources/Settings';
import * as H from 'history';
import { ISshKeyRequest } from 'common/api/resources/SshKey';
import { createProjectSshKey } from 'common/modules/project/actions';
import { PageSection } from 'common/components/PageHeader/Styles';
import ServerUserData from 'common/components/ServerUserData/ServerUserData';
import InternalError from 'common/components/ErrorPages/InternalError/InternalError';
import { hasPermission } from 'common/modules/permission/selectors';
import { PERMISSION_LIST } from 'common/modules/permission/constants';
import { PROJECT_SERVER_PAGE } from 'client/project/constants/tests';
import PlanPrice from 'client/common/components/PlanPrice/PlanPrice';
import BackupCard from 'common/containers/BackupCard/BackupCard';
import {
    defaultBackupSettings,
    IAdditionalDiskRequest,
    IBackupSettings,
} from 'common/api/resources/ComputeResourceVm';
import {
    IPlanResponse,
    LimitName,
} from 'common/api/resources/Plan';
import { setLastUsedSshKeys } from 'common/modules/app/lastUsedSshKeys/actions';
import {
    getHostnameTemplate,
    shouldRegisterFqdnOnServerCreate,
} from 'common/modules/settings/selectors';
import {
    requiredRule,
    validate,
} from 'common/validator';
import {
    IJSONSchemaForm,
    ISubmitEvent,
} from 'common/components/JSONSchemaForm/JSONSchemaForm';
import {
    convertToDataUnit,
    DataUnit,
} from 'common/helpers/units';
import * as formErrorsActions from 'common/modules/app/formErrors/actions';
import { getProcessedErrors } from 'common/modules/app/formErrors/selectors';
import ManagerOfDisabledEntities, { DisabledEntitiesManagerContext } from 'common/helpers/ManagerOfDisabledEntities';
import { CardWithSwitchContainer } from 'common/components/CardWithSwitch/Styles';
import {
    INTENT_TYPE,
    SIZE,
} from 'common/constants';
import { IpTypeCard } from 'common/components/IpTypeCard/IpTypeCard';
import { IpBlockType } from 'common/api/resources/IpBlock';
import { toggleElement } from 'common/helpers/array';
import { VerticalIndent } from 'common/components/styles/VerticalIndent';
import PrimaryDiskList from 'common/modules/computeResourceVm/containers/PrimaryDiskList';
import AdditionalDiskList from 'common/modules/computeResourceVm/containers/AdditionalDiskList';
import {
    IOfferResponse,
    OfferType,
} from 'common/api/resources/Offer';
import { Link } from 'react-router-dom';
import { pathTo } from 'common/helpers/core';
import VpcNetworkList from 'common/modules/computeResourceVm/containers/VpcNetworkList';
import { FEATURES } from 'common/modules/app/features/constants';

interface IProjectServerCreateProps {
    history: H.History;
}

export type ProjectServerCreateProps =
    IProjectServerCreateProps &
    ReturnType<typeof mapStateToProps> &
    ReturnType<typeof mapDispatchToProps>;

const defaultSubmitValues: IProjectCreateVmRequestWithBackup = {
    name: '',
    description: '',
    password: '',
    location_id: 0,
    plan_id: 0,
    ssh_keys: [],
    backup_settings: { ...defaultBackupSettings },
    ip_types: [IpBlockType.IPv4, IpBlockType.IPv6],
};

export const ProjectServerCreate: React.FC<ProjectServerCreateProps> = ({
    bakeToast,
    nameTemplate,
    generateHostname,
    generatePasswd,
    registerFqdn,
    locations,
    offers,
    plans,
    osImages,
    applications,
    canManageComputeResourceVms,
    formErrors,
    formErrorsActions: { setFormErrors, clearFormErrors },
    loadingFlags: {
        isLoading,
        isVmCreating,
    },
    loadCreateServerPageData,
    createProjectVm,
    createSshKey,
    setLastUsedSshKeysIds,
    projectId,
    project,
    history,
    hideUserData,
    hidePlanName,
    hidePlanSection,
    hideLocationSection,
    latestUsedSshKeysIds,
    tokenPricing,
    authUser,
    canCreateServers,
    canGetOffers,
    serverCreationSettings,
    canGetVpcNetworks,
    vpcNetworks,
    ignoreCompatibilityCheck,
}) => {
    const formRef = React.useRef<IJSONSchemaForm>(null);
    const [isSubmitting, setIsSubmitting] = React.useState(false);
    const [submitValues, setSubmitValues] = React.useState({
        ...defaultSubmitValues,
        name: generateHostname(nameTemplate),
        ssh_keys: latestUsedSshKeysIds,
        password: generatePasswd(),
        ip_types: serverCreationSettings.hide_ip_type_selection
            ? defaultSubmitValues.ip_types
            : serverCreationSettings.default_selected_ip_types,
    });
    const [filteredKeysIds, setFilteredKeysIds] = React.useState<number[]>([]);
    const [hasAccess, setHasAccess] = React.useState(true);
    const [internalError, setInternalError] = React.useState(false);
    const [errorMessage, setErrorMessage] = React.useState('');

    const selectedPlan = React.useMemo(
        () => plans.data.find(plan => plan.id === submitValues.plan_id),
        [submitValues.plan_id, plans]
    );

    const translate = useTranslate();

    const getDescription = (plan?: IPlanResponse): string => {
        if (plan) {
            return translate('projects.server.description', {
                vcpu: plan.params.vcpu,
                ram: convertToDataUnit(plan.params.ram, DataUnit.MiB),
                disk: plan.params.disk,
            }).toString();
        }

        return '';
    };

    React.useEffect(() => {
        const existingKeys = project.ssh_keys.map(key => key.id);
        let filteredKeys: number[] = [];
        const keys = latestUsedSshKeysIds.filter((key: number) => {
            if (existingKeys.includes(key)) {
                return true;
            } else {
                filteredKeys = [...filteredKeys, key];
                return false;
            }
        });
        setSubmitValues(values => ({
            ...values,
            ssh_keys: keys,
        }));
        setFilteredKeysIds(filteredKeys);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [project.ssh_keys]);

    React.useEffect(() => {
        setSubmitValues(values => ({
            ...values,
            name: generateHostname(nameTemplate),
        }));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [nameTemplate]);

    React.useEffect(() => {
        const defaultLocation = locations.data.find(item => item.is_default);
        if (defaultLocation) {
            setSubmitValues(values => ({
                ...values,
                location_id: defaultLocation.id,
            }));
        }
    }, [locations]);

    React.useEffect(() => {
        if (submitValues.plan_id !== 0) {
            return;
        }
        const defaultPlan = plans.data.find(item => item.is_default);
        if (defaultPlan) {
            setPlanValues(defaultPlan);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [plans]);

    React.useEffect(() => {
        const defaultOsImage = osImages.data.find(item => item.is_default);
        if (defaultOsImage && defaultOsImage.versions.length) {
            setSubmitValues(values => ({
                ...values,
                os_image_version_id: defaultOsImage.versions[0].id,
            }));
        }
    }, [osImages]);

    const selectedOffers = React.useMemo(() => {
        if (submitValues.primary_disk_offer) {
            return [submitValues.primary_disk_offer];
        }

        return [];
    }, [submitValues]);

    const manager = React.useMemo(() =>
        new ManagerOfDisabledEntities(
            {
                plans: plans.data,
                osImages: osImages.data,
                applications: applications.data,
                locations: locations.data,
                offers,
                vpcNetworks,
            }, {
                plan: submitValues.plan_id,
                osImageVersion: submitValues.os_image_version_id,
                application: submitValues.application_id,
                location: submitValues.location_id,
                offers: selectedOffers,
                vpcNetworks: submitValues.vpc_network_ids,
            }
        )
    , [
        plans,
        osImages,
        applications,
        locations,
        offers,
        vpcNetworks,
        submitValues,
        selectedOffers,
    ]);

    const [
        disabledPlanIds,
        disabledOsImageVersionIds,
        disabledOfferIds,
        disabledVpcNetworkIds,
    ] = React.useMemo(() => {
        if (ignoreCompatibilityCheck) {
            return [
                [],
                [],
                [],
                [],
            ];
        }

        return [
            manager.getDisabledPlanIds(),
            manager.getDisabledOsImageVersionIds(),
            manager.getDisabledOfferIds(),
            manager.getDisabledVpcNetworkIds(),
        ];
    }, [manager, ignoreCompatibilityCheck]);

    const onMount = async () => {
        try {
            await loadCreateServerPageData(projectId);

            setSubmitValues(values => ({
                ...values,
                application_data: {
                    email: authUser.email,
                    domain: values.name,
                    passwd: generatePasswd(),
                },
            }));
        } catch (e) {
            if (e.response.status === HTTP_CODES.FORBIDDEN) {
                setHasAccess(false);
            }

            if (e.response.status === HTTP_CODES.INTERNAL) {
                setInternalError(true);
            }

            throw e;
        }
    };

    const handleIpTypesToggle = (type: IpBlockType) => () => {
        const types: IpBlockType[] = submitValues.ip_types;

        if (types.length === 1 && types[0] === type) {
            return;
        }

        setSubmitValues(values => ({
            ...values,
            ip_types: toggleElement(types, type),
        }));
    };

    const handleSetUserData = (data: string | undefined) => {
        if (data) {
            setSubmitValues({ ...submitValues, user_data: data });
        } else if ('user_data' in submitValues) {
            const { user_data, ...values } = submitValues;
            setSubmitValues(values);
        }
    };

    const handlePrimaryDiskSelect = (offer: IOfferResponse) => {
        setSubmitValues(values => ({
            ...values,
            primary_disk_offer: values.primary_disk_offer === offer.id ? undefined : offer.id,
        }));
    };

    const handleAdditionalDiskAdded = (disk: IAdditionalDiskRequest) => {
        setSubmitValues(values => ({
            ...values,
            additional_disks: [...(values.additional_disks ?? []), disk],
        }));
    };

    const canCreate = (): boolean => {
        const values = { ...submitValues };

        delete values.description;

        return Object.values(values).every(item => item);
    };

    const clearValidation = () => {
        if (errorMessage.length) {
            setErrorMessage('');
        }
    };

    const handleGenerateName = () => {
        clearValidation();
        setSubmitValues({ ...submitValues, name: generateHostname(nameTemplate) });
    };

    const handlePasswordFocus = (e: React.FocusEvent<HTMLInputElement>) => {
        e?.target.select();
    };

    const handleCopyPassword = () => {
        copyToClipboard(submitValues.password);
        bakeToast(INTENT_TYPE.SUCCESS, 'copyText.copied');
    };

    const handleSshKeysSelect = (ids: number[]) => {
        setSubmitValues(values => ({ ...values, ssh_keys: ids }));
    };

    const handleChangeInput = (key: string) => (e: React.FormEvent<HTMLInputElement>) => {
        clearValidation();
        setSubmitValues({ ...submitValues, [key]: e.currentTarget.value });
    };

    const handlePlanSelect = (plan: IPlanResponse) => {
        setPlanValues(plan.id !== submitValues.plan_id ? plan : undefined);
    };

    const setPlanValues = (plan?: IPlanResponse) => {
        setSubmitValues(values => ({
            ...values,
            plan_id: plan ? plan.id : 0,
            description: plan ? getDescription(plan) : '',
            backup_settings: plan?.is_backup_available ? {
                ...values.backup_settings,
                limit: plan.limits[LimitName.BackupsNumber],
            } : defaultBackupSettings,
        }));
    };

    const handleLocationSelect = (id: number) => {
        setSubmitValues(values => ({
            ...values,
            location_id: values.location_id !== id ? id : 0,
        }));
    };

    const handleVersionSelect = (id: number) => {
        setSubmitValues(values => {
            delete values.application_id;

            return {
                ...values,
                os_image_version_id: values.os_image_version_id !== id ? id : 0,
                ssh_keys: values.os_image_version_id !== id && showSshKeys(id) ? values.ssh_keys : [],
            };
        });
    };

    const handleApplicationSelect = (id: number) => {
        setSubmitValues(values => {
            delete values.os_image_version_id;

            return {
                ...values,
                application_id: values.application_id !== id ? id : 0,
            };
        });
    };

    const handleSubmit = async () => {
        if (isSubmitting) {
            return;
        }

        clearFormErrors();

        setIsSubmitting(true);

        try {
            if (!canCreate()) {
                return;
            }
            if (formRef.current && !formRef.current.isValid()) {
                return;
            }

            const rules = {
                name: requiredRule(<Translate content="validate.fieldRequired" />),
            };

            const errors = validate<IProjectCreateVmRequestWithBackup>(submitValues, rules);

            if (Object.keys(errors).length) {
                setFormErrors(errors);
                return;
            }

            if (
                !ignoreCompatibilityCheck && (
                    (submitValues.plan_id && submitValues.plan_id > 0 && manager.isPlanDisabled(submitValues.plan_id))
                    || (submitValues.location_id && manager.isLocationDisabled(submitValues.location_id))
                    || (submitValues.os_image_version_id && manager.isOsImageVersionDisabled(submitValues.os_image_version_id))
                    || (submitValues.application_id && manager.isApplicationDisabled(submitValues.application_id))
                    || (submitValues.primary_disk_offer && manager.isOfferDisabled(submitValues.primary_disk_offer))
                    || (submitValues.additional_disks && submitValues.additional_disks.some(disk => manager.isOfferDisabled(disk.offer_id)))
                )
            ) {
                setErrorMessage(translate('validate.wrongConfiguration').toString());
                return;
            }

            clearValidation();
            const values = { ...submitValues };

            if (!values.application_id) {
                delete values.application_data;
            }

            if (registerFqdn) {
                values.fqdns = [values.name];
            }

            if (!values.backup_settings.enabled) {
                delete values.backup_settings.limit;
            }

            await createProjectVm(projectId, values);
            setLastUsedSshKeysIds([...values.ssh_keys, ...filteredKeysIds]);
            history.push(`/projects/${projectId}#servers`);
        } catch (e) {
            if (e.response.status === HTTP_CODES.VALIDATION_ERROR) {
                if (e.response.data.errors.user_data) {
                    setErrorMessage(e.response.data.errors.user_data.join(''));
                }
            }

            if (e.response.status >= HTTP_CODES.BAD_REQUEST) {
                setErrorMessage(e.response.data.message);
            }
        } finally {
            setIsSubmitting(false);
        }
    };

    const handleJsonFormError = () => {
        formRef.current?.scrollTo();
    };

    const handleApplicationDataChange = ({ formData }: ISubmitEvent) => {
        setSubmitValues(values => ({
            ...values,
            application_data: formData,
        }));
    };

    const handleSetBackupSettings = (backupSettings: IBackupSettings) => {
        setSubmitValues(values => ({
            ...values,
            backup_settings: backupSettings,
        }));
    };

    const showLocationSection = React.useMemo(() => {
        if (!hideLocationSection) {
            return true;
        }

        const visibleLocations = locations.data.filter(item => item.is_visible);
        return visibleLocations.length !== 1 || !visibleLocations[0].is_default;
    }, [hideLocationSection, locations.data]);

    const showPlanSection = React.useMemo(() => {
        if (!hidePlanSection) {
            return true;
        }

        const visiblePlans = plans.data.filter(item => item.is_visible);
        return visiblePlans.length !== 1 || !visiblePlans[0].is_default;
    }, [hidePlanSection, plans.data]);

    const showSshKeys = React.useCallback((id: number | undefined) => {
        for (const osImage of osImages.data) {
            const result = osImage.versions.find(item => item.id === id);
            if (result) {
                return result.is_ssh_keys_supported;
            }
        }

        return true;
    }, [osImages]);

    const hasPrimaryDiskOffers: boolean = offers.findIndex(offer => offer.type === OfferType.PRIMARY_DISK) !== -1;
    const hasAdditionalDiskOffers: boolean = offers.findIndex(offer => offer.type === OfferType.ADDITIONAL_DISK) !== -1;

    if (!canCreateServers || !hasAccess) {
        return (<Translate content="projects.server.create.accessDenied" />);
    }

    if (internalError) {
        return (<InternalError canSeeActualError={canManageComputeResourceVms} />);
    }

    return (
        <WithErrorPages onMount={onMount} deps={[projectId]}>
            <Container>
                <Loader isLoading={isLoading}>
                    <DisabledEntitiesManagerContext.Provider value={manager}>
                        <PageSection>
                            <Action
                                component={Link}
                                to={pathTo(`/projects/${projectId}#servers`)}
                            >
                                {project.name}
                            </Action>
                            <Icon name="chevron-right" />
                            <Translate content="projects.server.create.title" />
                        </PageSection>
                        {showLocationSection && (
                            <LocationList
                                selectedId={submitValues.location_id}
                                onItemClicked={handleLocationSelect}
                                disableLocationWithoutComputeResources={false}
                            />
                        )}
                        <ServerTypeTabs
                            onChange={handleApplicationDataChange}
                            onApplicationSelected={handleApplicationSelect}
                            onVersionSelected={handleVersionSelect}
                            selectedOsImageVersionId={submitValues.os_image_version_id || 0}
                            selectedApplicationId={submitValues.application_id || 0}
                            formData={submitValues.application_data}
                            formRef={formRef}
                            onError={handleJsonFormError}
                        />
                        {showPlanSection && (
                            <PlanList
                                selectedPlan={submitValues.plan_id}
                                onItemClicked={handlePlanSelect}
                                hidePlanName={hidePlanName}
                                project={project}
                            />
                        )}
                        {selectedPlan?.is_backup_available && (
                            <CardWithSwitchContainer>
                                <BackupCard
                                    backupSettings={submitValues.backup_settings}
                                    setBackupSettings={handleSetBackupSettings}
                                    isBackupSettingsUpdating={false}
                                    nextScheduledBackupAt={null}
                                    backupPrice={selectedPlan.backup_price}
                                    backupsNumber={submitValues.backup_settings.limit}
                                />
                            </CardWithSwitchContainer>
                        )}
                        {!serverCreationSettings.hide_ip_type_selection && (
                            <>
                                <VerticalIndent />
                                <PageSubHeader title="projects.server.create.ipBlockTypes" />
                                <Grid
                                    gap={SIZE.XS}
                                    xs={1}
                                    sm={2}
                                    md={3}
                                    lg={4}
                                    xl={5}
                                >
                                    <GridCol>
                                        <IpTypeCard
                                            isSelected={submitValues.ip_types.includes(IpBlockType.IPv4)}
                                            onSelect={handleIpTypesToggle(IpBlockType.IPv4)}
                                        >
                                            {IpBlockType.IPv4}
                                        </IpTypeCard>
                                    </GridCol>
                                    <GridCol>
                                        <IpTypeCard
                                            isSelected={submitValues.ip_types.includes(IpBlockType.IPv6)}
                                            onSelect={handleIpTypesToggle(IpBlockType.IPv6)}
                                        >
                                            {IpBlockType.IPv6}
                                        </IpTypeCard>
                                    </GridCol>
                                </Grid>
                            </>
                        )}
                        {canGetOffers && (
                            <>
                                {hasPrimaryDiskOffers && (
                                    <PrimaryDiskList
                                        selectedPlan={selectedPlan}
                                        selectedOffer={submitValues.primary_disk_offer}
                                        onSelect={handlePrimaryDiskSelect}
                                        tokenPricing={tokenPricing}
                                    />
                                )}
                                {hasAdditionalDiskOffers && (
                                    <AdditionalDiskList
                                        selectedPlan={selectedPlan}
                                        disks={submitValues.additional_disks ?? []}
                                        onDiskAdded={handleAdditionalDiskAdded}
                                        onDiskRemoved={(index: number) => {
                                            setSubmitValues(values => ({
                                                ...values,
                                                additional_disks: values.additional_disks?.filter((_, i) => i !== index),
                                            }));
                                        }}
                                        disabledOfferIds={disabledOfferIds}
                                        tokenPricing={tokenPricing}
                                    />
                                )}
                            </>
                        )}
                        <ServerNamesContainer>
                            <FormField name="name" label={null}>
                                <PageSubHeader title="projects.server.create.password" />
                                <ServerName hasErrors={false} withRefresh={true}>
                                    <input
                                        disabled
                                        value={submitValues.password}
                                        type="text"
                                        onFocus={handlePasswordFocus}
                                    />
                                    <Tooltip
                                        title={
                                            <Translate content="copyText.copy"/>
                                        }
                                    >
                                        <GenerateNameButton type="button" onClick={handleCopyPassword}>
                                            <Icon name="copy" />
                                        </GenerateNameButton>
                                    </Tooltip>
                                </ServerName>
                            </FormField>
                        </ServerNamesContainer>
                        {showSshKeys(submitValues.os_image_version_id) && (
                            <ServerSSHKeys
                                selected={submitValues.ssh_keys}
                                onSelected={handleSshKeysSelect}
                                sshKeys={project.ssh_keys}
                                create={createSshKey}
                            />
                        )}
                        {!hideUserData && (
                            <ServerUserData
                                isSelected={submitValues.user_data !== undefined}
                                isLinux={!!showSshKeys(submitValues.os_image_version_id)}
                                onSetUserData={handleSetUserData}
                                planId={submitValues.plan_id}
                                osImageVersionId={submitValues.os_image_version_id}
                                disabledPlanIds={disabledPlanIds}
                                disabledOsImageVersionIds={disabledOsImageVersionIds}
                            />
                        )}
                        {canGetVpcNetworks && (
                            <VpcNetworkList
                                disabledIds={disabledVpcNetworkIds}
                                selectedVpcNetworkIds={submitValues.vpc_network_ids ?? []}
                                onVpcNetworkToggled={(id: number) => {
                                    setSubmitValues(values => ({
                                        ...values,
                                        vpc_network_ids: toggleElement(values.vpc_network_ids ?? [], id),
                                    }));
                                }}
                            />
                        )}
                        <Form
                            id="serverCreateForm"
                            errors={formErrors}
                            hideRequiredLegend={true}
                            applyButton={false}
                            cancelButton={false}
                            submitButton={false}
                        >
                            <ServersContainer>
                                <ServerNamesContainer>
                                    <FormField name="name" label={null}>
                                        <PageSubHeader title="projects.server.create.serverName" />
                                        <ServerName hasErrors={false} withRefresh={true}>
                                            <input
                                                onChange={handleChangeInput('name')}
                                                value={submitValues.name}
                                                type="text"
                                            />
                                            <GenerateNameButton type="button" onClick={handleGenerateName}>
                                                <Icon name="refresh" />
                                            </GenerateNameButton>
                                        </ServerName>
                                    </FormField>
                                </ServerNamesContainer>
                                <ServerNamesContainer>
                                    <FormField name="description" label={null}>
                                        <PageSubHeader title="projects.server.create.serverDescription" />
                                        <ServerName hasErrors={false}>
                                            <input
                                                name="description"
                                                onChange={handleChangeInput('description')}
                                                value={submitValues.description}
                                                type="text"
                                            />
                                        </ServerName>
                                    </FormField>
                                </ServerNamesContainer>
                            </ServersContainer>
                        </Form>
                        <Footer>
                            <SubmitButton onClick={handleSubmit} isActive={canCreate()}>
                                <Button
                                    data-cy={PROJECT_SERVER_PAGE.CREATE_BUTTON}
                                    type="submit"
                                    form="serverCreateForm"
                                    intent="primary"
                                    state={isVmCreating ? 'loading' : undefined}
                                >
                                    <Translate content={plans.data.find(plan => project.token_pricing !== undefined) ?
                                        'projects.server.create.createAndBuyButton' :
                                        'projects.server.create.createButton'
                                    } />
                                </Button>
                            </SubmitButton>
                            <PlanPrice
                                planId={submitValues.plan_id}
                                isBackupEnabled={!!submitValues.backup_settings?.enabled}
                                primaryDiskOfferId={submitValues.primary_disk_offer}
                                additionalDisks={submitValues.additional_disks}
                            />
                            {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
                        </Footer>
                    </DisabledEntitiesManagerContext.Provider>
                </Loader>
            </Container>
        </WithErrorPages>
    );
};

const mapStateToProps = (state: RootState, ownProps: RouteComponentProps<{ id: string }>) => ({
    projectId: parseInt(ownProps.match.params.id, 10),
    project: state.project.projects.item,
    nameTemplate: getHostnameTemplate(state),
    generateHostname: generateName,
    generatePasswd: generatePassword,
    formErrors: getProcessedErrors(state),
    registerFqdn: shouldRegisterFqdnOnServerCreate(state),
    locations: state.location.list,
    plans: state.plan.list,
    osImages: state.osImage.list,
    applications: state.application.list,
    offers: state.offer.list.data,
    tokenPricing: state.project.projects.item?.token_pricing,
    loadingFlags: {
        isLoading: state.app.loadingFlags.has(LOADING_FLAGS.PROJECT_CREATE_SERVER),
        isVmCreating: state.app.loadingFlags.has(LOADING_FLAGS.CREATE_COMPUTE_RESOURCE_VM),
    },
    canManageComputeResourceVms: hasPermission(state, PERMISSION_LIST.MANAGE_SERVERS),
    hideUserData: isFeatureEnabled(FEATURE.HIDE_USER_DATA, state),
    hidePlanName: isFeatureEnabled(FEATURE.HIDE_PLAN_NAME, state),
    hidePlanSection: isFeatureEnabled(FEATURE.HIDE_PLAN_SECTION, state),
    hideLocationSection: isFeatureEnabled(FEATURE.HIDE_LOCATION_SECTION, state),
    latestUsedSshKeysIds: state.app.lastUsedSshKeys,
    authUser: state.auth.user,
    canCreateServers: hasPermission(state, PERMISSION_LIST.CREATE_SERVERS, PERMISSION_LIST.MANAGE_SERVERS),
    canGetOffers: hasPermission(state, PERMISSION_LIST.MANAGE_OFFERS) || hasPermission(state, PERMISSION_LIST.GET_OFFERS),
    canGetVpcNetworks: hasPermission(state, PERMISSION_LIST.MANAGE_ALL_VPC_NETWORKS)
        || hasPermission(state, PERMISSION_LIST.MANAGE_OWNED_VPC_NETWORKS),
    serverCreationSettings: state.settings.server_creation,
    vpcNetworks: state.vpcNetwork.list.data,
    ignoreCompatibilityCheck: state.app.features.has(FEATURES.IGNORE_COMPATIBILITY_CHECK),
});

const mapDispatchToProps = (dispatch: Dispatch, ownProps: RouteComponentProps<{ id: string }>) => ({
    bakeToast: bindActionCreators(toasterActions.bakeForegroundToast, dispatch),
    loadCreateServerPageData: bindActionCreators(projectServerActions.loadCreateServerPageData, dispatch),
    createProjectVm: bindActionCreators(projectServerActions.createProjectServer, dispatch),
    formErrorsActions: bindActionCreators(formErrorsActions, dispatch),
    createSshKey: (values: ISshKeyRequest) =>
        bindActionCreators(createProjectSshKey, dispatch)(parseInt(ownProps.match.params.id, 10), values),
    setLastUsedSshKeysIds: (keys: number[]) => dispatch(setLastUsedSshKeys(keys)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ProjectServerCreate);
