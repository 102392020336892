// Copyright 1999-2024. WebPros International GmbH. All rights reserved.

import * as React from 'react';
import {
    ItemCost,
    ItemPlanParams,
    PlanLabelItem,
    PlanLabels,
    PlanValuesList,
    Title,
} from 'common/components/plan/Styles';
import { Translate } from '@plesk/ui-library';
import { PROJECT_SERVER_PAGE } from 'client/project/constants/tests';
import {
    convertToDataUnit,
    DataUnit,
} from 'common/helpers/units';
import { Tooltip } from 'common/components/Tooltip/Tooltip';

export interface IPlanCardDescriptionProps {
    name: React.ReactNode | string;
    showName?: boolean;
    price?: {
        per_hour: string;
        per_month: string;
    };
    ram: number;
    vcpu: number;
    disk: number;
    showInnerTooltip?: boolean;
}

export const PlanCardDescription: React.FC<IPlanCardDescriptionProps> = ({
    name,
    showName,
    price,
    ram,
    vcpu,
    disk,
    showInnerTooltip = true,
}) => {
    const itemCostEl = React.useMemo(() => {
        if (price === undefined) {
            return <></>;
        }

        const perMonth = <Translate content="plan.price.perMonth" params={{ price: price.per_month }} />;
        const perHour = <Translate content="plan.price.perHour" params={{ price: price.per_hour }} />;

        return (
            <ItemCost>
                <div><Tooltip title={perMonth}><span>{perMonth}</span></Tooltip></div>
                <div><Tooltip title={perHour}><span>{perHour}</span></Tooltip></div>
            </ItemCost>
        );
    }, [price]);

    return (
        <div style={{ maxWidth: '90%' }}>
            {showName && (
                <Title>
                    <Tooltip title={name} shown={showInnerTooltip}><span>{name}</span></Tooltip>
                </Title>
            )}
            {itemCostEl}
            <ItemPlanParams>
                <PlanLabels data-cy={PROJECT_SERVER_PAGE.PLAN_CARD}>
                    <PlanLabelItem className="plan-param">
                        <Translate content="servers.create.plan.cpu" />
                    </PlanLabelItem>
                    <PlanLabelItem className="plan-param">
                        <Translate content="servers.create.plan.ram" />
                    </PlanLabelItem>
                    <PlanLabelItem className="plan-param">
                        <Translate content="servers.create.plan.disk" />
                    </PlanLabelItem>
                </PlanLabels>
                <PlanValuesList>
                    <div>{vcpu}</div>
                    <div>{convertToDataUnit(ram, DataUnit.MiB)} MiB</div>
                    <div>{disk} GiB</div>
                </PlanValuesList>
            </ItemPlanParams>
        </div>
    );
};