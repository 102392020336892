// Copyright 1999-2024. WebPros International GmbH. All rights reserved.

import { IRequest } from 'common/api/resources/Request/request';

export class QueryBuilder<I extends IRequest<object>> {
    private axiosRequestParams: Record<string, string> = {};
    private readonly request: I | IRequest<object>;

    constructor(request: I | undefined) {
        this.request = request || {
            filters: {},
        };
    }

    build(): Record<string, string> {
        if (!this.request) {
            return {};
        }

        this.applyFilters();
        this.applyTags();
        this.applyInclude();
        this.applyExclude();

        const {
            filters,
            tags,
            include,
            exclude,
            ...rest
        } = this.request;

        return {
            ...rest,
            ...this.axiosRequestParams,
        };
    }

    private applyFilters() {
        if (!this.request.filters) {
            return;
        }

        Object.keys(this.request.filters).forEach(key => {
            if (this.request.filters && this.request.filters[key]) {
                const filterValues = this.request.filters[key];

                this.axiosRequestParams[`filter[${key}]`] = Array.isArray(filterValues) ? filterValues.join(',') : filterValues;
            }
        });
    }

    private applyTags() {
        if (!this.request.tags) {
            return;
        }

        Object.keys(this.request.tags).forEach(key => {
            if (this.request.tags![key]) {
                const tagValues = this.request.tags![key];

                this.axiosRequestParams[`tag[${key}]`] = Array.isArray(tagValues) ? tagValues.join(',') : tagValues;
            }
        });
    }

    private applyInclude() {
        if (!this.request.include) {
            return;
        }

        this.axiosRequestParams.include = this.request.include.join(',');
    }

    private applyExclude() {
        if (!this.request.exclude) {
            return;
        }

        this.axiosRequestParams.exclude = this.request.exclude.join(',');
    }
}
