// Copyright 1999-2024. WebPros International GmbH. All rights reserved.

import * as React from 'react';
import {
    bindActionCreators,
    Dispatch,
} from 'redux';
import { connect } from 'react-redux';
import {
    IOfferResponse,
    OfferType,
} from 'common/api/resources/Offer';
import { getOffersNextPage } from 'common/modules/offer/actions';
import {
    Grid,
    GridCol,
    Translate,
} from '@plesk/ui-library';
import InfiniteScroll from 'common/components/InfinityScroll/InfinityScroll';
import {
    ICONS,
    SIZE,
} from 'common/constants';
import { PageSubHeader } from 'client/common/components';
import { IAppState as IClientState } from 'client/core/store';
import { PrimaryDiskCard } from 'common/modules/computeResourceVm/components/PrimaryDiskCard';
import { ICommonState } from 'common/store';
import { IPlanResponse } from 'common/api/resources/Plan';
import { IProjectTokenPricingResponse } from 'common/api/resources/Project';

export interface IPrimaryDiskListProps {
    selectedPlan?: IPlanResponse;
    onSelect?: (offer: IOfferResponse) => void;
    selectedOffer?: number;
    tokenPricing?: IProjectTokenPricingResponse;
}

export type PrimaryDiskListProps =
    IPrimaryDiskListProps &
    ReturnType<typeof mapStateToProps> &
    ReturnType<typeof mapDispatchToProps>;

export const PrimaryDiskList: React.FC<PrimaryDiskListProps> = ({
    selectedPlan,
    tokenPricing,
    offers,
    hasNextPage,
    loadNextPage,
    selectedOffer,
    onSelect,
}) => (
    <>
        <PageSubHeader title="servers.create.primaryDiskOffer" />
        <Translate content="servers.create.primaryDiskOfferDescription" />
        <InfiniteScroll
            loadMore={loadNextPage}
            hasMore={hasNextPage}
        >
            <Grid
                style={{ marginBottom: '-24px', marginTop: '24px' }}
                gap={SIZE.LG}
                xs={1}
                sm={2}
                md={3}
                lg={4}
                xl={5}
            >
                {offers.map((offer) => (
                    <GridCol key={offer.id}>
                        <PrimaryDiskCard
                            plan={selectedPlan}
                            tokenPricing={tokenPricing}
                            offer={offer}
                            icon={ICONS.CD}
                            isSelected={selectedOffer === offer.id}
                            onItemClicked={() => onSelect && onSelect(offer)}
                        />
                    </GridCol>
                ))}
            </Grid>
        </InfiniteScroll>
    </>
);

const mapStateToProps = (state: (IClientState | ICommonState)) => ({
    hasNextPage: !!state.offer.list.links.next,
    offers: state.offer.list.data.filter((offer) => offer.type === OfferType.PRIMARY_DISK),
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    loadNextPage: bindActionCreators(getOffersNextPage, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(PrimaryDiskList);