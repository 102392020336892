// Copyright 1999-2024. WebPros International GmbH. All rights reserved.

import * as React from 'react';
import {
    ComputeResourceVmCustomPlanResponse,
    IVmReinstallRequest,
    IVmResponse,
} from 'common/api/resources/ComputeResourceVm';
import {
    IAxiosApiResponse,
    IPaginateApiResponse,
} from 'common/api/resources/Response';
import { IOsImageResponse } from 'common/api/resources/OsImage';
import {
    ISshKeyRequest,
    ISshKeyResponse,
} from 'common/api/resources/SshKey';
import { HTTP_CODES } from 'common/api/constants';
import {
    Checkbox,
    copyToClipboard,
    Icon,
    Tooltip,
    Translate,
} from '@plesk/ui-library';
import { WithErrorPages } from 'common/components/WithPageNotFound/WithErrorPages';
import { Loader } from 'common/components';
import ServerTypeTabs from 'common/components/serverType/ServerTypeTabs';
import ServerSSHKeys from 'common/components/ServerSSHKeys/ServerSSHKeys';
import { ReinstallContainer } from 'common/components/ServerTabs/Styles';
import ButtonWithConfirmation from 'common/components/ButtonWithConfirmation';
import { INTENT_TYPE } from 'common/constants';
import {
    Footer,
    SubmitButton,
    ErrorMessage,
    ServerName,
    GenerateNameButton,
    ServerNamesContainer,
} from 'client/project/containers/projectServer/ProjectServerCreate/Styles';
import { SERVER_TABS } from 'common/components/ServerTabs/constants/tests';
import {
    IJSONSchemaForm,
    ISubmitEvent,
} from 'common/components/JSONSchemaForm/JSONSchemaForm';
import { VirtualizationType } from 'common/api/resources/ComputeResource';
import { IApplicationResponse } from 'common/api/resources/Application';
import ServerUserData from 'common/components/ServerUserData/ServerUserData';
import ManagerOfDisabledEntities, { DisabledEntitiesManagerContext } from 'common/helpers/ManagerOfDisabledEntities';
import { PageSubHeader } from 'client/common/components';
import { ChangeEvent } from 'react';
import * as toasterActions from 'common/modules/app/toaster/actions';

export const PASSWORD_INPUT = {
    input: 'password-input',
};

export interface IReinstallTabProps {
    osImages: IPaginateApiResponse<IOsImageResponse[]>;
    applications: IPaginateApiResponse<IApplicationResponse[]>;
    loadReinstallServerPageData: () => void;
    reinstallServer: (id: number, data: IVmReinstallRequest) => void;
    createSshKey: (values: ISshKeyRequest) => IAxiosApiResponse<ISshKeyResponse>;
    loadingFlags: {
        isLoading: boolean;
        isVmReinstalling: boolean;
    };
    generatePasswd: () => string;
    hideUserData: boolean;
    server: IVmResponse;
    sshKeys: ISshKeyResponse[];
    mountDeps: React.DependencyList;
    buttonColor: string;
    bakeToast: typeof toasterActions.bakeForegroundToast;
    allowPasswordInput?: boolean;
}

const defaultSubmitValues: IVmReinstallRequest = {
    os: 0,
    ssh_keys: [],
    password: '',
};

export const ReinstallTab: React.FC<IReinstallTabProps> = ({
    osImages,
    applications,
    loadingFlags: {
        isLoading,
        isVmReinstalling,
    },
    loadReinstallServerPageData,
    reinstallServer,
    createSshKey,
    generatePasswd,
    server,
    hideUserData,
    sshKeys,
    mountDeps,
    buttonColor,
    bakeToast,
    allowPasswordInput = false,
}) => {
    const formRef = React.useRef<IJSONSchemaForm>(null);
    const [submitValues, setSubmitValues] = React.useState<IVmReinstallRequest>({ ...defaultSubmitValues });
    const [hasAccess, setHasAccess] = React.useState(true);
    const [internalError, setInternalError] = React.useState(false);
    const [errorMessage, setErrorMessage] = React.useState('');
    const [resetPassword, setResetPassword] = React.useState(false);

    React.useEffect(() => {
        const osImageVersion = osImages.data.find(item => item.is_default);

        const values: IVmReinstallRequest = {
            ssh_keys: server.ssh_keys.map(item => item.id),
            os: osImageVersion?.versions.length ? osImageVersion.versions[0].id : defaultSubmitValues.os,
            password: '',
        };

        setSubmitValues(values);
    }, [osImages, server]);

    const manager = React.useMemo(() =>
        new ManagerOfDisabledEntities(
            {
                plans: [server.plan],
                osImages: osImages.data,
                applications: applications.data,
            },
            {
                plan: server.plan.is_custom ? server.plan as ComputeResourceVmCustomPlanResponse : server.plan.id,
            })
    , [server, osImages, applications]);

    const disabledOsImageVersionIds = React.useMemo(() => manager.getDisabledOsImageVersionIds(), [manager]);

    const onMount = async () => {
        try {
            await loadReinstallServerPageData();

            setSubmitValues(values => ({
                ...values,
                application_data: {
                    email: server.user.email,
                    domain: server.name,
                    passwd: generatePasswd(),
                },
            }));
        } catch (e) {
            if (e.response.status === HTTP_CODES.FORBIDDEN) {
                setHasAccess(false);
            }

            if (e.response.status === HTTP_CODES.INTERNAL) {
                setInternalError(true);
            }

            throw e;
        }
    };

    const handleSetUserData = (data: string | undefined) => {
        if (data) {
            setSubmitValues({ ...submitValues, user_data: data });
        } else if ('user_data' in submitValues) {
            const { user_data, ...values } = submitValues;
            setSubmitValues(values);
        }
    };

    const canReinstall = (): boolean => {
        const values = { ...submitValues };

        if (server.is_processing) {
            return false;
        }

        return (!!values.os || !!values.application) && (resetPassword ? !!values.password : true);
    };

    const clearValidation = () => {
        if (errorMessage.length) {
            setErrorMessage('');
        }
    };

    const handleSshKeysSelect = (ids: number[]) => {
        setSubmitValues(values => ({ ...values, ssh_keys: ids }));
    };

    const handleVersionSelect = (id: number) => {
        setSubmitValues(values => {
            delete values.application;

            return {
                ...values,
                os: values.os !== id ? id : 0,
                ssh_keys: values.os !== id && showSshKeys(id) ? values.ssh_keys : [],
            };
        });
    };

    const handleApplicationSelect = (id: number) => {
        setSubmitValues(values => {
            delete values.os;

            return {
                ...values,
                application: values.application !== id ? id : 0,
            };
        });
    };

    const handleReinstall = async () => {
        if (!canReinstall()) {
            return;
        }

        if (formRef.current && !formRef.current.isValid()) {
            return;
        }

        try {
            const values = { ...submitValues };

            if (!values.application) {
                delete values.application_data;
            }

            clearValidation();
            await reinstallServer(server.id, values);
        } catch (e) {
            if (e.response.status === HTTP_CODES.VALIDATION_ERROR) {
                if (e.response.data.errors.user_data) {
                    setErrorMessage(e.response.data.errors.user_data.join(''));
                }
            }

            if (e.response.status >= HTTP_CODES.BAD_REQUEST) {
                setErrorMessage(e.response.data.message);
            }
        }
    };

    const handleApplicationDataChange = ({ formData }: ISubmitEvent) => {
        setSubmitValues(values => ({
            ...values,
            application_data: formData,
        }));
    };

    const handleJsonFormError = () => {
        formRef.current?.scrollTo();
    };

    const showSshKeys = React.useCallback((id: number | undefined) => {
        for (const osImage of osImages.data) {
            const result = osImage.versions.find(item => item.id === id);
            if (result) {
                return result.is_ssh_keys_supported;
            }
        }

        return true;
    }, [osImages]);

    const handleResetPasswordChange = (value: boolean) => {
        setResetPassword(value);

        setSubmitValues(values => ({
            ...values,
            password: allowPasswordInput
                ? ''
                : (value ? generatePasswd() : ''),
        }));
    };

    const handlePasswordChange = (event: ChangeEvent<HTMLInputElement>) => {
        setSubmitValues(values => ({
            ...values,
            password: event.target.value,
        }));
    };

    const handleCopyPassword = () => {
        copyToClipboard(submitValues.password);
        bakeToast(INTENT_TYPE.SUCCESS, 'copyText.copied');
    };

    if (!hasAccess) {
        return (<Translate content="servers.tabs.reinstall.accessDenied" />);
    }

    if (internalError) {
        return (<Translate content="servers.tabs.reinstall.internalError" />);
    }

    return (
        <WithErrorPages onMount={onMount} deps={mountDeps}>
            <ReinstallContainer>
                <Loader isLoading={isLoading}>
                    <DisabledEntitiesManagerContext.Provider value={manager}>
                        <ServerTypeTabs
                            onChange={handleApplicationDataChange}
                            onApplicationSelected={handleApplicationSelect}
                            onVersionSelected={handleVersionSelect}
                            selectedOsImageVersionId={submitValues.os || 0}
                            selectedApplicationId={submitValues.application || 0}
                            onError={handleJsonFormError}
                            formRef={formRef}
                            formData={submitValues.application_data}
                            hideApplications={server.virtualization_type === VirtualizationType.VZ}
                        />

                        <ServerNamesContainer>
                            <PageSubHeader title="servers.tabs.reinstall.password"/>

                            <ServerName hasErrors={false} withRefresh={false}>
                                <div style={{ marginBottom: '10px' }}>
                                    <Checkbox
                                        defaultChecked={false}
                                        checked={resetPassword}
                                        onChange={handleResetPasswordChange}
                                    >
                                        <Translate content="servers.tabs.reinstall.resetPassword"/>
                                    </Checkbox>
                                </div>

                                <input
                                    type="text"
                                    data-cy={PASSWORD_INPUT.input}
                                    value={submitValues.password}
                                    onChange={handlePasswordChange}
                                    disabled={!allowPasswordInput || !resetPassword}
                                    required={resetPassword}
                                />

                                <Tooltip
                                    title={
                                        <Translate content="copyText.copy"/>
                                    }
                                >
                                    <GenerateNameButton
                                        type="button"
                                        onClick={handleCopyPassword}
                                        disabled={!submitValues.password}
                                    >
                                        <Icon name="copy"/>
                                    </GenerateNameButton>
                                </Tooltip>
                            </ServerName>
                        </ServerNamesContainer>

                        {showSshKeys(submitValues.os) && <ServerSSHKeys
                            selected={submitValues.ssh_keys}
                            onSelected={handleSshKeysSelect}
                            sshKeys={sshKeys}
                            create={createSshKey}
                        />}
                        {!hideUserData && server.virtualization_type === VirtualizationType.KVM && (
                            <ServerUserData
                                isSelected={!!submitValues.user_data}
                                isLinux={!!showSshKeys(submitValues.os)}
                                onSetUserData={handleSetUserData}
                                osImageVersionId={submitValues.os}
                                disabledOsImageVersionIds={disabledOsImageVersionIds}
                            />
                        )}
                        <Footer>
                            <SubmitButton isActive={true}>
                                <ButtonWithConfirmation
                                    disabled={!canReinstall()}
                                    confirmationButtonGhost={false}
                                    translations={{
                                        text: (
                                            <Translate content="servers.tabs.reinstall.popover.text" />
                                        ),
                                        button: (
                                            <Translate content="servers.tabs.reinstall.popover.button" />
                                        ),
                                        title: (
                                            <Translate content="servers.tabs.reinstall.popover.title" />
                                        ),
                                    }}
                                    handleConfirm={handleReinstall}
                                    buttonColor={buttonColor}
                                    data-cy={SERVER_TABS.REINSTALL_BUTTON}
                                    form="serverCreateForm"
                                    isLoading={isVmReinstalling}
                                    confirmationButtonText={<Translate content="servers.tabs.reinstall.button" />}
                                    buttonIntent={ INTENT_TYPE.PRIMARY }
                                    withStyledButton={true}
                                    placement="top-right"
                                />
                            </SubmitButton>
                            {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
                        </Footer>
                    </DisabledEntitiesManagerContext.Provider>
                </Loader>
            </ReinstallContainer>
        </WithErrorPages>
    );
};
